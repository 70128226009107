<template>
<div>
    <div v-if="isLoading">
      <CanPayLoader/>
    </div>
  <div class="container splash mt-4">
    <div class="row m-5">
      <div class="col-12 remove-padding">
        <span class="bl-heading"
          >Funding Account</span
        >
      </div>
    </div>
    <div class="bank-list" v-if="!isLoading">
      <div v-for="(bank, bankName) in bank_list.active_bank" :key="bankName">
        <div class="row m-2">
          <!-- <div class="col-12 remove-padding">
            <span class="float-left bl-bank-name bl-f-weight">{{ bankName }}</span>
          </div> -->
        </div>
        <div
          v-if="
            typeof bank.checking != 'undefined' && bank.checking.length != 0
          "
        >
          <div class="row m-2 pl-2">
            <div class="col-4 remove-padding">
              <span class="float-left">Checking</span>
            </div>
            <div class="col-8 pl-2 remove-padding">
              <span class="float-left font-weight-bold">PurchPower</span>

              <div
                @click="showModal('purchase-power-modal')"
                style="margin-right: 4.5rem"
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="16"
                  height="16"
                  fill="#000000"
                  viewBox="0 0 90 90"
                  style="enable-background: new 0 0 90 90"
                  xml:space="preserve"
                >
                  <path
                    d="M45,0C20.1,0,0,20.1,0,45s20.1,45,45,45s45-20.1,45-45C90,20.2,69.8,0,45,0z M45,82C24.6,82,8,65.4,8,45S24.6,8,45,8
	s37,16.6,37,37S65.4,82,45,82z M40,20c0-2.8,2.2-5,5-5s5,2.2,5,5s-2.2,5-5,5S40,22.8,40,20z M56,65.4V68c0,1.1-0.9,2-2,2H36
	c-1.1,0-2-0.9-2-2v-2.6c0-0.9,0.6-1.6,1.4-1.9l4.1-1.3c0.3-0.1,0.5-0.4,0.5-0.7V41h-3.1c-1.6,0-2.9-1.3-2.9-2.9c0-1.2,0.8-2.3,2-2.7
	l11.4-3.6c1.1-0.3,2.2,0.3,2.5,1.3c0.1,0.2,0.1,0.4,0.1,0.6v27.8c0,0.3,0.2,0.6,0.5,0.7l4.1,1.3C55.4,63.7,56,64.5,56,65.4z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="row" v-for="(account, i) in bank.checking" :key="i">
            <div class="col-12">
              <div class="row bank-name-list m-2">
                <div class="col-4 align-self-center bl-f-weight">
                  <span>x{{ account.account_no }}</span>
                </div>
                <div class="col-4 align-self-center bl-f-weight">
                  <span v-if="account.purchase_power!=blacklistedAccountNumber && account.purchase_power!=blockedRoutingNumber">$</span><span>{{ account.purchase_power }}</span>
                </div>
                <div class="col-2 align-self-center">
                </div>
                <div class="col-2 align-self-center">
                  <div class="text-center">
                    <input
                      :id="account.id"
                      class="radio-custom"
                      name="radio-group"
                      type="radio"
                      :ischecked="account.status"
                      :checked="account.status"
                      @click="selectBank(account)"
                      v-if="account.blocked_account_number == 0"
                    />
                    <label
                      :for="account.id"
                      class="radio-custom-label float-right"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="typeof bank.savings != 'undefined' && bank.savings.length != 0"
        >
          <div class="row m-2 pl-2">
            <div class="col-4 remove-padding">
              <span class="float-left">Savings</span>
            </div>
            <div class="col-8 pl-2 remove-padding">
              <span class="float-left font-weight-bold">PurchPower</span>

              <div
                @click="showModal('purchase-power-modal')"
                style="margin-right: 4.5rem"
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="16"
                  height="16"
                  fill="#000000"
                  viewBox="0 0 90 90"
                  style="enable-background: new 0 0 90 90"
                  xml:space="preserve"
                >
                  <path
                    d="M45,0C20.1,0,0,20.1,0,45s20.1,45,45,45s45-20.1,45-45C90,20.2,69.8,0,45,0z M45,82C24.6,82,8,65.4,8,45S24.6,8,45,8
	s37,16.6,37,37S65.4,82,45,82z M40,20c0-2.8,2.2-5,5-5s5,2.2,5,5s-2.2,5-5,5S40,22.8,40,20z M56,65.4V68c0,1.1-0.9,2-2,2H36
	c-1.1,0-2-0.9-2-2v-2.6c0-0.9,0.6-1.6,1.4-1.9l4.1-1.3c0.3-0.1,0.5-0.4,0.5-0.7V41h-3.1c-1.6,0-2.9-1.3-2.9-2.9c0-1.2,0.8-2.3,2-2.7
	l11.4-3.6c1.1-0.3,2.2,0.3,2.5,1.3c0.1,0.2,0.1,0.4,0.1,0.6v27.8c0,0.3,0.2,0.6,0.5,0.7l4.1,1.3C55.4,63.7,56,64.5,56,65.4z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="row" v-for="(account, i) in bank.savings" :key="i">
            <div class="col-12">
              <div class="row bank-name-list m-2">
                <div class="col-4 align-self-center bl-f-weight">
                  <span>x{{ account.account_no }}</span>
                </div>
                <div class="col-4 align-self-center bl-f-weight">
                  <span v-if="account.purchase_power!=blacklistedAccountNumber && account.purchase_power!=blockedRoutingNumber">$</span><span>{{ account.purchase_power }}</span>
                </div>
                <div class="col-2 align-self-center">
                </div>
                <div class="col-2 align-self-center">
                  <div class="text-center">
                    <input
                      :id="account.id"
                      class="radio-custom"
                      name="radio-group"
                      type="radio"
                      :ischecked="account.status"
                      :checked="account.status"
                      v-if="account.blocked_account_number == 0"
                      @click="selectBank(account)"
                    />
                    <label
                      :for="account.id"
                      class="radio-custom-label float-right"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isLoading">
      <div
        class="row mt-5 justify-content-center"
      >
        <div class="col-12 col-md-6 col-lg-6 d-flex flex-column">
          <button
            type="button"
            class="btn-login-splash w-100"
            v-on:click="clickClose"
          >
            Save and Close
          </button>
        </div>
      </div> 
    </div>

    <b-modal
      ref="purchase-power-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="pay-modal-center"
      centered
      title="BootstrapVue"
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="col-12 text-center mt-5 mb-5">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns:cc="http://creativecommons.org/ns#"
              xmlns:dc="http://purl.org/dc/elements/1.1/"
              xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 26.5 33.1"
              style="enable-background: new 0 0 26.5 33.1"
              xml:space="preserve"
              width="75"
              height="75"
              fill="#149240"
            >
              <g transform="translate(0,-270.54165)">
                <path
                  d="M17.1,272.1c-0.3,0-0.6,0.1-0.8,0.2l-5.6,3.2H3.4c-1,0-1.8,0.8-1.8,1.8v16.3c0,1,0.8,1.8,1.8,1.8h18.8c1,0,1.8-0.8,1.8-1.8
		v-4.8h0.4c0.3,0,0.5-0.2,0.5-0.5v-4.7c0-0.3-0.2-0.5-0.5-0.5h-0.4v-5.7c0-1-0.8-1.8-1.8-1.8h-2l-1.5-2.6
		C18.3,272.4,17.7,272.1,17.1,272.1L17.1,272.1z M17.2,273.2c0.2,0,0.4,0.1,0.6,0.4l2.5,4.4H8.7l8-4.6
		C16.9,273.2,17,273.2,17.2,273.2L17.2,273.2z M3.4,276.6h5.4l-2.2,1.2H4.1c-0.7,0-0.7,1,0,1h18.8v4.2h-3.3c-1.6,0-2.9,1.3-2.9,2.9
		s1.3,2.9,2.9,2.9h3.3v4.8c0,0.4-0.3,0.7-0.7,0.7H3.4c-0.4,0-0.7-0.3-0.7-0.7v-16.3C2.6,277,2.9,276.6,3.4,276.6L3.4,276.6z
		 M20.8,276.6h1.4c0.4,0,0.7,0.3,0.7,0.7v0.5h-1.4L20.8,276.6z M19.6,284.1h4.3v3.6h-4.3c-1,0-1.8-0.8-1.8-1.8
		C17.7,284.9,18.5,284.1,19.6,284.1z M19.8,284.9c-0.6,0-1.1,0.5-1.1,1c0,0.6,0.5,1,1.1,1s1.1-0.5,1.1-1
		C20.9,285.4,20.4,284.9,19.8,284.9z"
                />
              </g>
            </svg>
          </div>
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              <b>What is my Purchase Power?</b>
            </label>
          </div>
          <br />

          <h3 class="purchasepower-modal-text text-justify">
            Purchase Power is your available spending with CanPay. Your Purchase
            Power is impacted by many factors including recent purchases
            through CanPay, your total CanPay spending history, and how long
            you've had a CanPay account. Negative items, like NSF payment
            returns, can also impact your Purchase Power.
          </h3>

          <div class="text-center mt-5">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="hideModal('purchase-power-modal')"
            >
              <label class="purchasepower-modal-ok-label">OK</label>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="update-bank-modal"
      hide-footer
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="update-bank-modal"
      centered
    >
      <div class="color">
        <div class="col-12 text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120"
            height="120"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            fill="#e14343"
          >
            <path
              d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
            />
          </svg>
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              There was some problem trying to link your bank account. Please
              Update your banking details.
            </label>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-12 text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="changeBankAccount()"
              >
                <span class="purchasepower-modal-ok-label">Update Banking</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Validation modal -->
    <b-modal
      ref="validation-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="validation-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              {{ error_message }}
            </label>
          </div>
          <br />
          <br />
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="hidevalidationModal"
            >
              <label class="purchasepower-modal-ok-label">OK</label>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <real-account-number-modal page_name="banklist" ref="RealAccountNumberModal"></real-account-number-modal>
  </div>
    <!--- Problematic Account Modal Start --->
    <problematic-account-modal ref="ProblematicAccountModal" :handleCanPayLoader="handleCanPayLoader" :connectMXBankAccount="connectMXBankAccount"></problematic-account-modal>
    <!--- Problematic Account Modal End --->
</div>
</template>
<script>
import { db } from "../../firebaseConfig.js";
import api from "../../api/account.js";
import Loading from "vue-loading-overlay";
import constants from "../Common/constant.js";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
import RealAccountNumberModal from './RealAccountNumberModal.vue';
import NoPrimaryAccountModal from './NoPrimaryAccountModal.vue';
import ProblematicAccountModal from "../Modal/ProblematicAccountModal.vue"
export default {
  name: "successBankList",
  /**
   * @description -
   * @returns {any}
   * qr_url => this will store the qr-url from generate qrcode api
   * qr_code => this will store the qr code in base64 format
   * payment_pin => this will store the payment pin
   */

  data() {
    let self = this;
    return {
      isLoading: false,
      fullPage: true,
      bank_list: [],
      blacklistedAccountNumber: constants.blacklistedAccountNumber,
      blockedRoutingNumber: constants.blockedRoutingNumber,
      bank_id: "",
      banking_solution: "",
      account_no: "",
      error_message: "",
      bank_changed: false,
      currentUser: null,
      registered_user: true,
      problematic_account_details:{
        bank_name:'',
        account_no:'',
        active_bank_id:''
      },
      fix_connection:false
    };
  },
  created() {},

  mounted() {
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    this.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
    var app = document.getElementById("app");
    if (app) {
      app.style.setProperty("background-color", "#ffffff");
    }
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#ffffff");
    }
    var element = document.getElementsByClassName("wrapper");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#ffffff");
    }
    if (localStorage.getItem('consumer_token') != null) {
      this.getBankAccountList();
    } else if (localStorage.getItem('session_data') != null || localStorage.getItem('sessionId') != null) {
      this.getRegisteredUserBankAccountList();
    } else {
      this.$router.push("/login");
    }
  },
  components: {
    Loading,
    CanPayLoader,
    RealAccountNumberModal,
    NoPrimaryAccountModal,
    ProblematicAccountModal
  },
  watch: {
    bank_id: function (newval, oldval) {
      this.bank_changed = false;
      if (newval != oldval) {
        this.bank_changed = true;
      }
    },
  },
  methods: {
    handleCanPayLoader(value){
      this.isLoading = value;
    },
    handleWidgetEvent(event) {
      var self = this;
      console.log('MX PostMessage: ', event);
      if (event.type === 'mx/connect/memberConnected') {
        console.log("Got here");
        self.$router.push({ name: 'mx-success', params: { 'user_guid': event.metadata.user_guid, 'member_guid':event.metadata.member_guid, 'mx_user_type':'challenged', 'fix_connection': self.fix_connection, 'active_bank_id': self.problematic_account_details.active_bank_id } })
        if(self.fix_connection == true){
          self.fix_connection = false;
        }
      } else if (event.type === 'mx/connect/loaded') {
        // Handle widget loading completion
      }
    },
    connectMXBankAccount() {
      let self = this;
      self.fix_connection = true;
      this.isLoading = true;
      let request = {
            active_bank_id:this.problematic_account_details.active_bank_id
      };
      api
        .directLinkGenerateForMx(request)
        .then((response) => {
          if (response.code == 200) {
            const finicityConnectUrl = response.data;
            window.finicityConnect.launch(finicityConnectUrl, {
              selector: "#connect-container",
              overlay: "rgba(255,255,255, 0)",
              success: function (data) {
                console.log("Yay! We got data", data);
                if (data.code == 200) {
                  if (self.update_bank == true) {
                    // self.updateBankDetails();
                  } else {
                    //now store the details at canpay end
                    self.storeBankDetails();
                    setTimeout(() => {
                      self.$refs.RealAccountNumberModal.checkForRealAccountNumber();
                    }, 2000);
                  }
                }
              },
              cancel: function () {
                self.isLoading = false;
                console.log("The user cancelled the iframe");
              },
              error: function (err) {
                console.log(err);
                self.isLoading = false;
                console.error(
                  "Some runtime error was generated during Finicity Connect",
                  err
                );
              },
              loaded: function () {
                self.isLoading = false;
                console.log(
                  "This gets called only once after the iframe has finished loading"
                );
              },
              route: function (event) {
                self.isLoading = false;
                console.log(
                  "This is called as the user progresses through Connect"
                );
              },
              user: function (event) {
                if (event.data.errorCode) {
                  console.log(event.data.data.institutionId);
                  setTimeout(() => {
                    window.finicityConnect.destroy();
                    //if error code is present then call the connect fix api
                    self.generateConnectFix(
                      event.data.data.institutionId,
                      null
                    );
                  }, 2000);
                }
              },
            });
          } else {
            self.isLoading = false;
          }
        })
        .catch((err) => {
          self.fix_connection = false;
          self.isLoading = false;
          console.log(err);
        });
    },
    getBankAccountList() {
      var self = this;
      self.isLoading = true;
      api
        .getBankAccountList()
        .then((response) => {
          self.bank_list = response.data.data;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.isLoading = false;
        });
    },
    getRegisteredUserBankAccountList() {
      var self = this;
      self.isLoading = true;
      var request = {
        id: localStorage.getItem('user_id'),
      };
      api
        .getUserBankAccountList(request)
        .then((response) => {
          self.bank_list = response.data.data;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.isLoading = false;
        });
    },
    changeBankAccount() {
      let self = this;
      self.$router.push("/banklinking");
    },
    clickCloseRedrest() {
      if (localStorage.getItem('consumer_token') != null) {
        this.$router.push("/pay");
      } else if (localStorage.getItem('session_data') != null) { // Registration 
        this.$router.push("/registrationcomple");
      } else if (localStorage.getItem('sessionId') != null) { // Onboarding
        this.$router.push("/registrationsuccess");
      } else {
        this.$router.push("/login");
      }
    },
    clickClose() {
      var self = this;
      if (localStorage.getItem('session_data') !== null || localStorage.getItem('sessionId') !== null) {
        // Registration or Onboarding
        self.registered_user = false;
      }
      var request = {
        id: self.bank_id,
        banking_solution: self.banking_solution,
      };
      if (localStorage.getItem('user_id') != null) {
        request.consumer_id = localStorage.getItem('user_id');
      }
      if (self.bank_id != '' && self.registered_user) {
        api
          .saveBankDetailsFromList(request)
          .then((response) => {
            console.log(response);
            if (response && response.data) {
              self.currentUser.account_no = response.data.slice(-4);
              localStorage.setItem("consumer_login_response", JSON.stringify(self.currentUser));
              self.getBankAccountList();
            }
            
            self.isLoading = false;
            self.clickCloseRedrest();
            // this.$router.push("/pay");
          })
          .catch(function (error) {
            self.isLoading = false;
            if (error.response.data.data == 1) {
              self.$refs["update-bank-modal"].show();
            }else if(error.response.data.code == 597){
              self.generateConnectFix(
                error.response.data.data.institutionId
              );
            } else if (error.response.data.code == 598) {
              self.bank_changed = false;
            }
          });
      } else if (self.bank_id != '' && !self.registered_user) {
        api
          .switchBankFromList(request)
          .then((response) => {
            console.log(response);
            self.isLoading = false;
            self.clickCloseRedrest();
            // this.$router.push("/pay");
          })
          .catch(function (error) {
            self.isLoading = false;
            if (error.response.data.data == 1) {
              self.$refs["update-bank-modal"].show();
            }else if(error.response.data.code == 597){
              self.generateConnectFix(
                error.response.data.data.institutionId
              );
            } else if (error.response.data.code == 598) {
              self.bank_changed = false;
            }
          });
      } else {
        self.clickCloseRedrest();
      }
      
    },
    generateConnectFix(id) {
      let self = this;
      this.isLoading = true;
      var request = {
        institution_id: id,
      };
      api
        .generateConnectFix(request)
        .then((response) => {
          if (response.code == 200) {
            const finicityConnectUrl = response.data.link;
            window.finicityConnect.launch(finicityConnectUrl, {
              selector: "#connect-container",
              overlay: "rgba(255,255,255, 0)",
              success: function (data) {
                console.log("Yay! We got data", data);
                if (data.code == 200) {
                  //now store the details at canpay end
                  self.storeBankDetails();
                }
              },
              cancel: function () {
                self.isLoading = false;
                console.log("The user cancelled the iframe");
              },
              error: function (err) {
                console.log(err);
                self.isLoading = false;
                console.error(
                  "Some runtime error was generated during Finicity Connect",
                  err
                );
              },
              loaded: function () {
                self.isLoading = false;
                console.log(
                  "This gets called only once after the iframe has finished loading"
                );
              },
              route: function (event) {
                self.isLoading = false;
                console.log(
                  "This is called as the user progresses through Connect"
                );
              },
              user: function (event) {
                if (event.data.errorCode) {
                  console.log(event.data.data.institutionId);
                  setTimeout(() => {
                    window.finicityConnect.destroy();
                    //if error code is present then call the connect fix api
                    self.generateConnectFix(event.data.data.institutionId);
                  }, 2000);
                }
              },
            });
          } else {
            self.isLoading = false;
          }
        })
        .catch((err) => {
          self.isLoading = false;
          console.log(err);
        });
    },
    storeBankDetails(is_exit=0) {
      var self = this;
      this.isLoading = true;
      api
        .updateBank()
        .then((response) => {
          if (response.code == 200) {
            localStorage.setItem(
              "consumer_login_response",
              JSON.stringify(response.data)
            );
            this.getBankAccountList();
            setTimeout(() => {
              this.$refs.RealAccountNumberModal.checkForRealAccountNumber();
            }, 2000);
          }
        })
        .catch(function (error) {
          self.isLoading = false;
          if (error.response.data.code == 598) {
            self.$refs.NoPrimaryAccountModal.showModal();
          } else if(error.response.data.code == 597 && is_exit == 0){
            self.generateConnectFix(error.response.data.data.institutionId);
          }else {
            self.showValidationModal(error.response.data.message);
          }
        });
    },
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },
    selectBank(account) {
      
      var target = document.getElementById(account.id);
      target.checked = true;
      this.bank_id = account.id;
      this.banking_solution = account.banking_solution_name;
      if (target.getAttribute("ischecked") == 0) {
        this.account_no = account.account_no;
      }
    },
    getdata() {
      let self = this;
      self.purchasepowerNoPrimaryAccountModelCalled = true;
      self.$bvModal.hide("delink-modal");
      let ref = db
        .collection("users")
        .doc(String(String(this.currentUser.user_id)));
      ref.get().then((snapshot) => {


        ref.onSnapshot((convo) => {
          let source = convo.metadata.hasPendingWrites ? "Local" : "Server";
          // TODO: add messages to store
          let ref = db
            .collection("users")
            .doc(String(this.currentUser.user_id));
          ref.get().then((snapshot) => {
            if (snapshot.exists) {
              
              this.users = snapshot.data();
              const containsKey = (obj, key) => Object.keys(obj).includes(key);
              const hasName = containsKey(this.users, "transaction_modification");
              const hasAllBankDelinked = containsKey(this.users, "all_bank_delinked");
              const hasPostToBankName = containsKey(this.users, "post_to_bank");

              const hasExchangeRateNotification = containsKey(this.users, "exchange_rate_notification");
              const hasRewardWheelLottery = containsKey(this.users, "lottery_winning_details");
              const mxActionNeeded = containsKey(this.users,"mx_action_needed");
              this.$bvModal.hide("account-no-modal");
              if(hasAllBankDelinked == true){
                if (self.currentUser.user_id!='' && this.users.all_bank_delinked != null) {
                  const hasBanklLinkType = containsKey(this.users, "bank_link_type");
                  if(hasBanklLinkType){
                    self.currentUser.account_id = this.users.active_account_id;
                    self.currentUser.account_no = this.users.active_account_no;
                    self.currentUser.bank_link_type = this.users.bank_link_type;
                    localStorage.setItem(
                      "consumer_login_response",
                      JSON.stringify(self.currentUser)
                    );
                  }
                  if (this.users.all_bank_delinked == 1) {
                    this.$bvModal.hide("delink-modal");
                    this.$refs.NoPrimaryAccountModal.showModal();
                  } else {
                    this.$refs.NoPrimaryAccountModal.hideModal();
                    self.setdata('all_bank_delinked');
                  }
                } else if(self.currentUser.account_no == 'XXXX'){
                  this.$refs.NoPrimaryAccountModal.showModal();
                }
              }else if(self.currentUser.account_no == 'XXXX'){
                this.$refs.NoPrimaryAccountModal.showModal();
              }
              if (hasName == true) {
                
                if (this.users.transaction_modification != null) {

                  const expiryTime = new Date(this.users.transaction_modification.expiry_time);

                  const currentTime=new Date(new Date().toLocaleString("en-US", {timeZone: this.users.transaction_modification.time_zone}));
                  if(expiryTime < currentTime)
                  {
                    self.setdata('transaction_modification');
                  }
                  else{
                    self.setdata('transaction_successful');
                    self.$router.push("/updatedtransaction");
                  }
                }
              }
              if (hasPostToBankName == true) {
                if (this.users.post_to_bank != null) {
                  if (Object.keys(this.users.post_to_bank).length > 0) {
                    self.$router.push("/acceptedtransaction");
                  }
                }
              }

              if(hasExchangeRateNotification == true){
                if(this.users.exchange_rate_notification == 1){
                  const event = new CustomEvent('exchangeRateNotification', {detail: {modal: true}});
                  document.dispatchEvent(event);
                }else{
                  const event = new CustomEvent('exchangeRateNotification', {detail: {modal: false}});
                  document.dispatchEvent(event);
                }
              }

              if(hasRewardWheelLottery == true){
                if(this.users.lottery_winning_details){
                  const event = new CustomEvent('lotteryNotification', {detail: {modal: true, details: this.users.lottery_winning_details}});
                  document.dispatchEvent(event);
                }else{
                  const event = new CustomEvent('lotteryNotification', {detail: {modal: false}});
                  document.dispatchEvent(event);
                }
              }
              console.log(this.users.mx_action_needed);
              if(mxActionNeeded == true){
                if(this.users.mx_action_needed && this.users.mx_action_needed.mx_user_action_needed == 1){
                 
                    this.problematic_account_details.bank_name = this.users.mx_action_needed.bank_name;
                    this.problematic_account_details.account_no = this.users.mx_action_needed.account_no;
                    this.problematic_account_details.active_bank_id = this.users.mx_action_needed.active_bank_id;
                    setTimeout(()=>{
                      this.$refs.ProblematicAccountModal.showModal();
                    },1500);
                }
              }
            }
          });
        });
      });
    },
    setdata(type) {
      var data = {};
      if(type == 'transaction_modification'){
        data = {
          transaction_modification: null,
        };
      }else if(type == 'transaction_successful'){
        data = {
          transaction_successful: null,
        };
      }else if(type == 'purchase_power_updated'){
        data = {
          purchase_power_updated: null
        };
      }
      else if(type == 'all_bank_delinked'){
        data = {
          all_bank_delinked: null,
        };
      }
      else if(type == 'problematic_status'){
        data = {
          mx_action_needed: null
        }
      }
      else{
        return true;
      }
      var self = this;
      var washingtonRef = db
        .collection("users")
        .doc(String(this.currentUser.user_id));
      // Set the "capital" field of the city 'DC'
      return washingtonRef
        .update(data)
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
  },
};
</script>
<style lang="scss">
#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#refresh-balance-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#disable-bank-success-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#confirm-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.bank-list::-webkit-scrollbar {
  width: 10px;
}

.bank-list::-webkit-scrollbar-track {
  background: #ffffff; 
}
 
.bank-list::-webkit-scrollbar-thumb {
  background: #dfdfdf; 
}

.bank-list::-webkit-scrollbar-thumb:hover {
  background: #dfdfdf; 
}
</style>